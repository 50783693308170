import axios from 'axios';

const API_URL = 'https://cloudapi.lykdat.com/v1/search';
export async function search(image, apiKey, catalogName) {
  const fd = new FormData();
  fd.append('image', image);
  fd.append('api_key', apiKey);
  fd.append('catalog_name', catalogName);

  const response =  await axios.post(API_URL, fd, { contentType: 'multipart/form-data' })
  return response.data
}