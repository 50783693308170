import React, { useState } from 'react';
import { search } from './helpers/apiClient';
import SearchInput from './components/SearchInput/SearchInput';
import './App.css';
import SearchResult from './components/SearchResult/SearchResult';

function App() {
  const [searching, setSearching] = useState(false);
  const [results, setResults] = useState([]);
  const onImagesPicked = async ({apiKey, catalogName, images}) => {
    setSearching(true);
    const results_ = [];
    for (const img of images) {
      try {
        const response = await search(img, apiKey, catalogName);
        response.data.result_groups.forEach((group, idx) => {
          const products = group.similar_products.slice(0, 8);
          results_.push({image: img, products, item: group.detected_item.name})
        });
        setResults([...results_])
      } catch (error) {
        console.log(error)
      }
    }
    setSearching(false);
  };

  const searchInput = <SearchInput onImagesPicked={onImagesPicked} />
  const resultsDom = results.map((result, idx) => (
    <SearchResult key={idx} image={result.image} products={result.products} item={result.item} />
  ))

  return (
    <div className='App'>
      <header className='App-header'>
        <div className='App-logo'>
          <span className='App-name'>Image Search Playground</span>
        </div>
      </header>
      <div className='App-body'>
        {searching ? <div>Searching ...</div> : searchInput}
        {resultsDom}
      </div>
    </div>
  );
}

export default App;
