import React from 'react';
import './SearchResult.css';

function SearchResult(props) {
  const { image, products, item } = props;
  const imgURL = URL.createObjectURL(image);
  const productCards = products.map((product, idx) => (
    <div key={idx} className="product-card">
      <img src={product.matching_image} height="300px" alt="query searched with" />
      <div className="card-body">
        <div>{product.name}</div>
        <div>{product.currency} {product.price}</div>
        <div className="product-id">{product.id}</div>
      </div>
    </div>
  ))

  return (
    <div className='search-result'>
      <div className="query-image">
        <img height="100px" src={imgURL} alt="prodcut"/>
        <div>{item}</div>
      </div>
      {productCards}
    </div>
  );
}

export default SearchResult;
